<template>
  <span
    class="vanguard-description"
    v-if="description"
    v-html="renderHTML(description)"
  ></span>
  <i
    v-if="descriptionDetail"
    class="fas fa-info-circle ms-1 vanguard-description-detail vanguard-tooltip"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    :title="renderHTML(descriptionDetail)"
    data-bs-html="true"
  ></i>
</template>

<script>
export default {
  name: "ChoiceVanguardDescription",
  props: {
    choice: {
      type: Object,
    },
  },
  watch: {
    descriptionDetail: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          console.log("descriptionDetail", this.descriptionDetail);
          document.dispatchEvent(
            new CustomEvent("vanguard-description-detail-updated"),
          );
        });
      },
    },
  },
  computed: {
    description() {
      // This is computed because choice may change after init
      return this?.choice?.vanguardDescription;
    },
    descriptionDetail() {
      return this?.choice?.vanguardDescriptionDetail;
    },
  },
};
</script>
