<template>
  <div class="checkout-crosssells-product">
    <ProductExtraProducts
      v-if="productReference"
      :productReference="productReference"
      :showaddtocart="false"
      :showprocessorder="true"
      :showBeforeText="true"
      :showAfterText="true"
    />
  </div>
</template>

<script>
import ProductExtraProducts from "@/components/product/ExtraProducts.vue";
import { useOrderHistoryStore } from "@/store/orderHistory";

export default {
  name: "CheckoutCrosssells",
  components: { ProductExtraProducts },
  data() {
    return {
      productReference: null,
    };
  },
  computed: {
    historyLastUpdate() {
      return useOrderHistoryStore().lastUpdate;
    },
  },
  mounted() {
    this.findProductReference();
  },
  watch: {
    historyLastUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.findProductReference();
      },
    },
  },
  methods: {
    findProductReference() {
      if (
        useOrderHistoryStore().orderHistory &&
        useOrderHistoryStore().orderHistory.length &&
        window?.productDetails?.extrasells
      ) {
        const step = useOrderHistoryStore().orderHistory.slice(-1)[0].step;
        const stepExtraProduct = window.productDetails.extrasells.find(
          (extra) => extra.step === step.toString(),
        );
        if (stepExtraProduct) {
          stepExtraProduct.productFileName = `productDetail-${stepExtraProduct.productDetailId}.json`;
          this.productReference = stepExtraProduct;
        } else {
          this.productReference = null;
        }
      }
    },
  },
};
</script>
