<template>
  <div v-if="argument1" v-html="renderHTML(argument1)"></div>
</template>

<script>
export default {
  name: "ProductArgument1",
  data() {
    return {
      argument1: null,
    };
  },
  mounted() {
    this.argument1 = window.productDetails.argument1;
  },
};
</script>
