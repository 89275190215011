<template>
  <AsyncComp />
</template>

<script>
import { defineAsyncComponent } from "vue/dist/vue.esm-bundler.js";
import axios from "axios";

// Cart
import cartprocess from "@/components/cart/Process.vue";
import carttable from "@/components/cart/Table.vue";
import carttotals from "@/components/cart/Totals.vue";
import cartcoupon from "@/components/cart/Coupon.vue";
import cartextrasell from "@/components/cart/ExtraSell.vue";
import cartfreeshippingprogression from "@/components/cart/FreeShippingProgression.vue";
import cartminicart from "@/components/cart/MiniCart.vue";
import carttablechoicelist from "@/components/cart/table/ChoiceList.vue";
import carttableupselllist from "@/components/cart/table/UpsellList.vue";
import carttableupselldiff from "@/components/cart/table/UpsellDiff.vue";
// Category
import categorylongdescription from "@/components/category/LongDescription.vue";
import categoryshortdescription from "@/components/category/ShortDescription.vue";
import categorysubcategories from "@/components/category/SubCategories.vue";
import categorylongname from "@/components/category/LongName.vue";
import categoryname from "@/components/category/Name.vue";
import categoryicon from "@/components/category/Image.vue";
import categoryimage from "@/components/category/Image.vue";
// Checkout
import checkoutbuttoncanceloneclick from "@/components/checkout/ButtonCancelOneClick.vue";
import checkoutcontactform from "@/components/checkout/ContactForm.vue";
import checkoutpaymentform from "@/components/checkout/PaymentForm.vue";
import checkoutorderrecap from "@/components/checkout/OrderRecap.vue";
import paymentrecurrenceswitch from "@/components/checkout/PaymentRecurrenceSwitch.vue";
import checkoutcrosssells from "@/components/checkout/Crosssells.vue";
import checkoutbuttonprocessorder from "@/components/checkout/ButtonProcessOrder.vue";
// Product
import productargument1 from "@/components/product/Argument1.vue";
import productargument2 from "@/components/product/Argument2.vue";
import productargument3 from "@/components/product/Argument3.vue";
import productargument4 from "@/components/product/Argument4.vue";
import productargument5 from "@/components/product/Argument5.vue";
import productargument from "@/components/product/ProductArgument.vue";
import productcategories from "@/components/product/Categories.vue";
import productbclchoices from "@/components/product/ChoicesBcl.vue";
import productnbclchoices from "@/components/product/ChoicesNbcl.vue";
import productdescription from "@/components/product/ProductContent.vue";
import productshortdescription from "@/components/product/ProductContent.vue";
import productextraproducts from "@/components/product/ExtraProducts.vue";
import productimages from "@/components/product/Images.vue";
import productlongname from "@/components/product/LongName.vue";
import productname from "@/components/product/Name.vue";
import productfeaturedname from "@/components/product/FeaturedProductName.vue";
import productratings from "@/components/product/Ratings.vue";
import productvisualstock from "@/components/product/VisualStock.vue";
import productconfigurablechoices from "@/components/product/ProductConfigurableChoices.vue";
import productprogressbar from "@/components/product/ProgressBar.vue";
import customizeproduct from "@/components/product/CustomizeProduct.vue";
// Choice
import choicefreelabel from "@/components/choice/FreeLabel.vue";
import choicevanguarddescription from "@/components/choice/VanguardDescription.vue";
import choicevanguardlabel from "@/components/choice/VanguardLabel.vue";
import choiceupselldescription from "@/components/choice/UpsellDescription.vue";
import choiceprice from "@/components/choice/Price.vue";

// Waiting pages
import waitingogone from "@/components/waiting/Payment.vue";
import waitingdoubleoptin from "@/components/waiting/DoubleOptin.vue";
import waitingpayment from "@/components/waiting/Payment.vue";

import addtocart from "@/components/AddToCart.vue";
import cookiedeclaration from "@/components/CookieDeclaration.vue";
import breadcrumbs from "@/components/Breadcrumbs.vue";
import debug from "@/components/Debug.vue";
import loader from "@/components/Loader.vue";
import mainmenu from "@/components/Menu.vue";
import productlist from "@/components/productlist/ProductList.vue";
import seo from "@/components/Seo.vue";
import tracking from "@/components/Tracking.vue";
import orderpageprocess from "@/components/OrderPageProcess.vue";
import logo from "@/components/Logo.vue";

import mounted from "@/services/mounted.js";
import Utils from "@/services/utils";

export default {
  name: "App",
  components: {
    AsyncComp: defineAsyncComponent(() => {
      const indexFile = document
        .getElementById("app")
        .hasAttribute("data-index-file")
        ? document.getElementById("app").getAttribute("data-index-file")
        : null;

      const getComponents = () => {
        return {
          cartprocess,
          carttable,
          carttotals,
          cartcoupon,
          cartextrasell,
          cartfreeshippingprogression,
          cartminicart,
          carttablechoicelist,
          carttableupselldiff,

          categorylongdescription,
          categoryshortdescription,
          categorysubcategories,
          categorylongname,
          categoryname,
          categoryicon,
          categoryimage,

          checkoutbuttoncanceloneclick,
          checkoutcontactform,
          checkoutpaymentform,
          checkoutorderrecap,
          paymentrecurrenceswitch,
          checkoutcrosssells,
          checkoutbuttonprocessorder,

          productcategories,
          productbclchoices,
          productnbclchoices,
          productextraproducts,
          productimages,
          productargument1,
          productargument,
          productargument2,
          productargument3,
          productargument4,
          productargument5,
          productratings,
          productdescription,
          productshortdescription,
          productvisualstock,
          productlongname,
          productname,
          productfeaturedname,
          productconfigurablechoices,
          productprogressbar,
          customizeproduct,

          carttableupselllist,
          choicefreelabel,
          choicevanguarddescription,
          choicevanguardlabel,
          choiceprice,
          choiceupselldescription,

          waitingogone,
          waitingdoubleoptin,
          waitingpayment,

          addtocart,
          breadcrumbs,
          cookiedeclaration,
          debug,
          loader,
          mainmenu,
          productlist,
          seo,
          tracking,
          orderpageprocess,
          logo,
        };
      };

      const getTemplate = (pathName) => {
        if (pathName.indexOf("previewapp") !== -1) {
          return pathName.replace("previewapp", "previewindex");
        }
        if (indexFile) {
          return indexFile;
        }

        if (pathName === "/") {
          return `/pages/home/index.html`;
        } else {
          return `/pages/${pathName.replace(/^\/|\/$/g, "")}/index.html`;
        }
      };

      return new Promise((resolve, reject) => {
        const searchURL = new URL(window.location);

        if (process.env.VUE_APP_SENTRY_ENV !== "production") {
          console.log(process.env);
        }

        axios.get(getTemplate(searchURL.pathname)).then((response) => {
          //console.log(response.data);
          resolve({
            components: getComponents(),
            template: response.data,
            async mounted() {
              this.$i18n.locale = window.globalConfig.lang;
              this.$nextTick(() => {
                document.dispatchEvent(new Event("vue-app-mounted"));
              });
            },
          });
        });
      });
    }),
  },
};
</script>
