<template>
  <div class="row justify-content-center grid-vp">
    <TransitionGroup name="pagination-effect-grid-vp">
      <template v-for="product in products" :key="product.id">
        <div
          :class="productClass"
          v-if="
            !isNaN(product.startDate) &&
            !isNaN(product.endDate) &&
            product.startingPrice
          "
          v-bind:data-product-id="product.id"
        >
          <ProductVp
            :product="product"
            :textInButton="textInButton"
            :isProductListFromParent="isProductListFromParent"
          />
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>
<script>
import ProductVp from "@/components/productlist/subcomponents/ProductVp.vue";

export default {
  name: "GridVP",
  components: {
    ProductVp,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    columnNumber: {
      type: String,
      default: 4,
    },
    textInButton: {
      type: String,
    },
    isProductListFromParent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productClass() {
      const base = "product-item";
      switch (this.columnNumber) {
        case "2":
          return `${base} col-sm-6 mb-4`;
        case "3":
          return `${base} col-sm-4 mb-4`;
        case "4":
        default:
          return `${base} col-sm-3 mb-4`;
      }
    },
  },
};
</script>
