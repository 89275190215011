<template>
  <div class="order-recap">
    <ul>
      <li v-for="(item, index) in cart?.items" v-bind:id="index">
        <span class="qty">{{ item.qty }}</span>
        <span class="separator">x</span>
        <span class="product-name">{{ item.name }}</span>
        <span class="separator">-</span>
        <span class="choice-description">{{ item.choice.description }}</span>
        <template v-if="item.customText">
          <span class="separator">-</span>
          <span class="item-custom-text-wrapper">
            <span class="item-custom-text-label"
              >{{ $t("labels.customText") }}:</span
            >
            <span
              :class="['item-custom-text', lowerCaseAndJoin(item.customFont)]"
              >{{ item.customText }}</span
            >
          </span>
        </template>
        <span class="separator">{{ $t("labels.for") }}</span>
        <span class="amount">{{ formatPrice(getAmount(item)) }}</span>
        <span class="currency">€</span>
        <template
          v-if="
            item.choice.bcl &&
            item.choice.hasOwnProperty('maxTermNumber') &&
            item.choice?.maxTermNumber > 0
          "
        >
          <span class="choice-description-bcl ps-1">
            <span class="choice-description-bcl-label-then mx-1">{{
              $t("labels.then").toLowerCase()
            }}</span>
            <span class="choice-description-bcl-duration">{{
              item.choice.maxTermNumber - 1
            }}</span>
            <span class="choice-description-bcl-separator separator">x</span>
            <span class="choice-description-bcl-price">{{
              formatPrice(item.choice.defaultPrice)
            }}</span>
            <span class="choice-description-bcl-currency">€</span>
            <span class="choice-description-bcl-by">
              /{{
                item.choice.renawalTermLength > 1
                  ? `${item.choice.renawalTermLength}${$t("labels.months")}`
                  : $t("labels.month")
              }}</span
            >
          </span>
          <br />
          <span class="choice-description-bcl-next-payment"
            >{{ $t("labels.nextPayment") }} {{ item.choice.renewalTermLength }}
            {{
              item.choice.renewalTermLength > 1
                ? $t("labels.months")
                : $t("labels.month")
            }}</span
          >
        </template>
      </li>
    </ul>
    <div class="subtotal" v-if="subTotal">
      <span class="label">{{ $t("labels.subTotal") }}</span>
      <span class="separator">:</span>
      <span class="amount">{{ subTotal }}</span>
      <span class="currency">€</span>
    </div>
    <div class="shipping-fees" v-if="shippingPrice">
      <span class="label">{{ $t("labels.shippingFees") }}</span>
      <span class="separator">:</span>
      <span class="amount">{{ shippingPrice }}</span>
      <span class="currency">€</span>
    </div>
    <div v-if="coupons" class="coupons">
      <div v-for="coupon in coupons">
        <span class="label">{{ coupon.code }}</span>
        <span
          v-if="coupon.discountAmount && coupon.isPercentage"
          class="discount-details"
        >
          (-{{ coupon.discountAmount }}%)
        </span>
        <span v-if="coupon.discountedAmount" class="separator">:</span>
        <span v-if="coupon.discountedAmount" class="amount"
          >-{{ formatPrice(coupon.discountedAmount) }}</span
        >
        <span v-if="coupon.discountedAmount" class="currency">€</span>
      </div>
    </div>
    <div class="total" v-if="total">
      {{ $t("labels.total") }} : {{ total }}<span class="currency">€</span>
    </div>
  </div>
</template>

<script>
import { useOrderHistoryStore } from "@/store/orderHistory";
import CartService from "@/services/cart";
import Shipping from "@/services/shipping";
import Price from "@/services/price";
import Utils from "@/services/utils";

export default {
  name: "CheckoutOrderRecap",
  data() {
    return {
      order: {},
      cart: {},
      shippingPrice: 0,
    };
  },
  mounted() {
    CartService.emptyCart();
    this.order = this.getHistoryItem()?.order;
    this.cart = this.getHistoryItem()?.cart;
    this.shippingPrice = this.getShippingPrice();
  },
  computed: {
    historyLastUpdate() {
      return useOrderHistoryStore().lastUpdate;
    },
    subTotal() {
      if (this?.cart?.defaultSubtotal) {
        return this.formatPrice(
          this.cart.defaultSubtotal
            ? this.cart.defaultSubtotal
            : this.cart.subtotal,
        );
      }
    },
    total() {
      if (this?.cart?.total) {
        return this.formatPrice(this.cart.total);
      }
    },
    coupons() {
      return this?.cart?.coupons;
    },
  },
  watch: {
    historyLastUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.order = this.getHistoryItem().order;
        this.cart = this.getHistoryItem().cart;
        this.shippingPrice = this.getShippingPrice();
      },
    },
  },
  methods: {
    getHistoryItem() {
      return useOrderHistoryStore().orderHistory?.slice(-1)[0];
    },
    getShippingPrice() {
      if (!this?.cart?.items) {
        return 0;
      }

      return this.formatPrice(
        Shipping.getShippingPriceTotal(
          this.cart.items,
          this.order.shippingCountry,
        ),
      );
    },
    getAmount(item) {
      let price = 0;

      if (
        item.choice.hasOwnProperty("startingPrice") &&
        item.choice?.startingPriceDuration > 0
      ) {
        price = item.choice.startingPrice;
      } else {
        price = item.choice.defaultPrice ?? item.choice.unitPrice;
      }
      return price;
    },
    formatPrice(price) {
      return Price.format(price);
    },
    lowerCaseAndJoin(str) {
      return Utils.lowerCaseAndJoin(str);
    },
  },
};
</script>
