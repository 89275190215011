import { useCartStore } from "@/store/cart";
import Shipping from "@/services/shipping";
import { useDraftStore } from "@/store/draft";
import CouponService from "@/services/coupon";
import Logger from "@/services/logger";
import { useTrackingStore } from "@/store/tracking";

export default class CartService {
  static addToCartLoading(loading) {
    if (loading) {
      // starts loading
      useCartStore().updateCartLoading(loading);
    } else {
      // ends loading
      useCartStore().updateCartLoading(loading);
      document.dispatchEvent(new Event("vue-app-product-added-to-cart"));
    }
  }

  static updateCartLoading(loading) {
    if (loading) {
      // starts loading
      useCartStore().updateCartLoading(loading);
    } else {
      // ends loading
      useCartStore().updateCartLoading(loading);
    }
  }

  static addToCart(itemToAdd) {
    this.addToCartLoading(true);

    //We need at least defaultPrice or startingPrice to add item to cart
    if (
      !itemToAdd.qtyIncr ||
      !itemToAdd.name ||
      !itemToAdd.choice.id ||
      !itemToAdd.choice.name ||
      !itemToAdd.choice?.hasOwnProperty("unitPrice") ||
      (!itemToAdd.choice?.hasOwnProperty("defaultPrice") &&
        !itemToAdd.choice?.hasOwnProperty("startingPrice"))
    ) {
      console.error("missing data to add item to cart");
      console.log(itemToAdd);
      return;
    }

    useCartStore().cart.shippingCountry =
      window.globalConfig.defaultCountryCode || "FRA";

    const existingItemInCart = useCartStore().cart.items.find(
      (item) =>
        item.choice.id === itemToAdd.choice.id &&
        item.isFromCoupon === itemToAdd.isFromCoupon,
    );

    if (
      existingItemInCart &&
      window.globalConfig.shopAllowQtyModification &&
      !itemToAdd.isFromCoupon
    ) {
      existingItemInCart.qty += itemToAdd.qtyIncr;
    } else {
      if (existingItemInCart && !window.globalConfig.shopAllowQtyModification) {
        const existingItemIndex =
          useCartStore().cart.items.indexOf(existingItemInCart);
        useCartStore().cart.items.splice(existingItemIndex, 1); // removing existing item
      }

      const cleanItemToAdd = JSON.parse(JSON.stringify(itemToAdd)); // deep copy
      cleanItemToAdd.qty = itemToAdd.qtyIncr;
      delete cleanItemToAdd.qtyIncr;

      console.log("addToCart", itemToAdd);
      Logger.log("AddToCart", {
        product: itemToAdd.name,
        choice: itemToAdd.choice.name,
        qty: cleanItemToAdd.qty,
      });

      useCartStore().cart.items.push(cleanItemToAdd);
    }

    this.updateCart();

    if (useTrackingStore().urlParams.coupon) {
      const coupon = useTrackingStore().urlParams.coupon.toUpperCase().trim();
      const minifiedCart = CouponService.addCouponInMinifiedCart(coupon);
      CouponService.checkCoupons(minifiedCart, false)
        .then(() => {
          console.log("Coupon added");
        })
        .catch(() => {
          console.log("Coupon is not for this product/choice");
        });
    }

    this.addToCartLoading(false);
  }

  static updateCart() {
    CartService.updateCartLoading(true);

    this.recalculateCart();
    useCartStore().saveCart();

    document.dispatchEvent(
      new CustomEvent("cart-updated", { detail: useCartStore().cart }),
    );
    CartService.updateCartLoading(false);
  }

  static recalculateCart() {
    const cart = useCartStore().cart;

    let defaultSubtotal = 0;
    let subtotal = 0;

    for (const item of cart.items) {
      item.defaultSubtotal = this.calculateDefaultSubtotal(item);
      item.subtotal = item.qty * item.choice.unitPrice;
      subtotal += item.subtotal;
      defaultSubtotal += item.defaultSubtotal;

      if (item.qty === 0) {
        cart.items.splice(cart.items.indexOf(item), 1);
      }
    }
    if (cart.items.length === 0) {
      cart.coupons = {}; // empty coupon if empty cart
    }

    cart.defaultSubtotal = defaultSubtotal;
    cart.subtotal = subtotal;

    cart.shippingPrice = this.getShippingPriceTotal();
    cart.freeShippingProgression = Shipping.freeShippingProgression(
      useCartStore().cart.items,
    );
    cart.total = cart.subtotal + cart.shippingPrice;

    if (cart.coupons && Object.keys(cart.coupons).length) {
      CouponService.checkCoupons(this.minifyCart()); // async
    }

    return cart;
  }

  static deleteItem(itemToDelete) {
    Logger.log("deleteItem", {
      product: itemToDelete.name,
    });

    CartService.updateCartLoading(true);

    const existingItemInCart = useCartStore().cart.items.find(
      (item) =>
        item.choice.id === itemToDelete.choice.id &&
        item.isFromCoupon === itemToDelete.isFromCoupon,
    );
    if (existingItemInCart) {
      existingItemInCart.qty = 0;
    }
    this.updateCart();

    CartService.updateCartLoading(false);
  }

  static emptyCart() {
    Logger.log("emptyCart");

    useCartStore().updateCart({ items: [] });
    this.updateCart();
  }

  static getShippingPriceTotal() {
    const countryCode =
      useDraftStore().selectedCountryCode ||
      window.globalConfig.defaultCountryCode ||
      "FRA";

    return Shipping.getShippingPriceTotal(
      useCartStore().cart.items,
      countryCode,
    );
  }

  static minifyCart() {
    const attributesToKeep = [
      "items",
      "coupons",
      "shippingCountry",
      "defaultCountryCode",
    ];
    const cartItemAttributesToKeep = [
      "id",
      "salesforceId",
      "choice",
      "qty",
      "isFromCoupon",
      "customText",
      "customFont",
    ];
    const choiceAttributesToKeep = ["id", "productId", "salesforceProductId"];

    const minifiedCart = JSON.parse(JSON.stringify(useCartStore().cart)); // deep copy

    for (const cartKey in minifiedCart) {
      if (!attributesToKeep.includes(cartKey)) {
        delete minifiedCart[cartKey];
      }
    }
    for (const item of minifiedCart.items) {
      for (const productKey in item) {
        if (!cartItemAttributesToKeep.includes(productKey)) {
          delete item[productKey];
        }
        for (const choiceKey in item.choice) {
          if (!choiceAttributesToKeep.includes(choiceKey)) {
            delete item.choice[choiceKey];
          }
        }
      }
    }

    return minifiedCart;
  }

  static calculateDefaultSubtotal(item) {
    let price = 0;
    if (
      item.choice.hasOwnProperty("startingPrice") &&
      item.choice?.startingPriceDuration > 0
    ) {
      price = item.choice.startingPrice;
    } else {
      price = item.choice.defaultPrice ?? item.choice.unitPrice;
    }

    return item.qty * price;
  }

  static generateVirtualMinifyCart(product, choice, coupon) {
    const virtualCart = {
      items: [
        {
          id: product.id,
          salesforceId: product.salesforceId,
          qty: 1,
          choice: {
            id: choice.id,
          },
        },
      ],
      coupons: {},
      shippingCountry: window.globalConfig.defaultCountryCode || "FRA",
    };

    virtualCart.coupons[coupon] = {
      code: coupon,
    };
    return virtualCart;
  }
}
