<template>
  <div>
    <span class="promotion-label-container" v-if="product.promotionLabel">
      <span class="promotion-label">
        {{ product.promotionLabel }}
      </span>
    </span>
    <span class="price-wrapper">
      <span v-if="product.oldPrice" class="old-price-wrapper">
        <span class="old-price">
          <span class="amount">{{ formatPrice(product.oldPrice) }}</span>
          <span class="currency">&euro;</span>
        </span>
      </span>
      <span
        class="price"
        v-if="
          !product.isSingleProduct &&
          product.hasOwnProperty('startingPrice') &&
          product.startingPriceDuration > 0
        "
      >
        <span class="starting-offer">
          <span class="amount">{{ formatPrice(product.startingPrice) }}</span>
          <span class="currency">&euro;</span>
          <span class="starting-offer-detail">{{
            displayDuration(product.startingPriceDuration, true)
          }}</span>
        </span>
        <span class="after-starting-offer">
          <span class="after-starting-offer-details"
            >{{ $t("labels.then") }}&nbsp;</span
          >
          <template v-if="product.defaultPrice !== 0">
            <span class="amount">{{ formatPrice(product.defaultPrice) }}</span>
            <span class="currency">&euro;</span>
            <span class="after-starting-offer-details">
              / {{ displayDuration(product.renewalTermLength) }}</span
            >
          </template>
          <template v-else>
            <span class="after-starting-offer-details">{{
              product.freeLabel
            }}</span>
          </template>
        </span>
      </span>
      <span class="price" v-else>
        <span class="amount">{{ formatPrice(product.unitPrice) }}</span>
        <span class="currency">&euro;</span>
      </span>
    </span>
  </div>
</template>

<script>
import Utils from "@/services/utils";
import Price from "@/services/price";

export default {
  name: "ProductListPrice",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
    formatPrice(price) {
      return Price.format(price);
    },
  },
};
</script>
