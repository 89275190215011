import { defineStore } from "pinia";
import { constants } from "@/constants";
import MultiLocalStorage from "@/services/multiLocalStorage";

export const useUpselledStore = defineStore("upselled", {
  state: () => ({
    _upselledItems: null,
  }),
  getters: {
    upselledItems: (state) => {
      if (!state._upselledItems) {
        const upselledItems = MultiLocalStorage.get(
          constants.UPSELLED,
          window?.globalConfig?.domainId,
        );
        state._upselledItems = upselledItems ? upselledItems : {};
      }

      return state._upselledItems;
    },
  },
  actions: {
    updateUpselled(upselledItems) {
      this._upselledItems = upselledItems;
      MultiLocalStorage.set(
        constants.UPSELLED,
        window?.globalConfig?.domainId,
        this._upselledItems,
      );
    },
    upgradeChoice(downgradeChoice, upsellChoice) {
      const upselledItems = this.upselledItems;
      upselledItems[upsellChoice.id] = downgradeChoice;
      this.updateUpselled(upselledItems);
    },
    downgradeChoice(upsellChoice) {
      const upselledItems = this.upselledItems;
      delete upselledItems[upsellChoice.id];
      this.updateUpselled(upselledItems);
    },
    getDowngradeChoice(upsellChoice) {
      const upselledItems = this.upselledItems;
      return upselledItems[upsellChoice.id];
    },
  },
});
