import sha1 from "js-sha1";
import { v4 as uuidv4 } from "uuid";
import translations from "@/i18n/messages.json";

export default class Utils {
  static conditioningValueClassPrefix = "conditioning-value";
  static conditionedItemClassPrefix = "conditional-item";

  static generateUID() {
    return sha1(uuidv4() + uuidv4() + new Date().getTime());
  }

  static displayDuration(duration, lang, startingPrice = false) {
    if (!duration) return "";

    const t = translations[lang].labels;
    duration = parseInt(duration);

    if (duration % 12 === 0) {
      // Round years
      const years = duration / 12;
      if (years === 1) {
        return startingPrice ? " " + t.theFirstYear : " " + t.year;
      } else {
        return startingPrice
          ? ` ${t.thePlural} ${years} ${t.firstYears}`
          : `${years} ${t.years}`;
      }
    } else {
      // Months
      if (duration === 1) {
        return startingPrice ? " " + t.theFirstMonth : " " + t.month;
      } else {
        return startingPrice
          ? ` ${t.thePlural} ${duration} ${t.firstMonths}`
          : `${duration} ${t.months}`;
      }
    }
  }

  static handleConditionedContent() {
    const conditionedValueClassPrefix = this.conditioningValueClassPrefix + "-";
    const conditionedItemClassPrefix = this.conditionedItemClassPrefix + "-";
    const conditionedItems = document.querySelectorAll(
      `[class*="${conditionedItemClassPrefix}"]`,
    );

    // Make disappear every conditionedItems if the corresponding conditionedValue has empty content
    for (const conditionedItem of conditionedItems) {
      const conditionedItemClasses = conditionedItem.className.split(" ");
      for (const conditionedItemClass of conditionedItemClasses) {
        if (conditionedItemClass.indexOf(conditionedItemClassPrefix) !== -1) {
          const id = conditionedItemClass.replace(
            conditionedItemClassPrefix,
            "",
          );
          const conditionedValue = document.querySelector(
            `.${conditionedValueClassPrefix}${id}`,
          );
          if (
            conditionedValue &&
            this.checkConditionedContent(conditionedValue)
          ) {
            conditionedItem.style.display = "none";
          } else {
            conditionedItem.style.display = "block";
          }
        }
      }
    }
  }

  static checkConditionedContent(conditionedValue) {
    const conditionedValueChild = conditionedValue
      ? conditionedValue.children[0]
      : null;
    const pattern1 = "<!---->"; // VueJS replaces empty components by this string.
    const pattern2 = "<!--v-if-->"; // VueJS replaces components hidden by an if by this string.
    const pattern3 = "<p><br></p>"; // Sometimes we have this by default with froala in Salesforce
    const patternToCheck = [pattern1, pattern2, pattern3];
    return (
      conditionedValue.innerHTML === "" ||
      patternToCheck.includes(conditionedValue.innerHTML) ||
      (conditionedValueChild &&
        (conditionedValueChild.innerHTML === "" ||
          patternToCheck.includes(conditionedValueChild.innerHTML)))
    );
  }

  static addConditionedContentListener() {
    const observer = new MutationObserver((mutations) => {
      let callHandleConditionedContent = false;

      mutations.forEach((mutation) => {
        if (mutation.type !== "childList" || callHandleConditionedContent)
          return;

        const rootItemClasses = mutation.target.classList;
        const addedNodesClasses =
          mutation.addedNodes.length !== 0
            ? this.getClassesFromNode(mutation.addedNodes)
            : [];
        const removedNodesClasses =
          mutation.removedNodes.length !== 0
            ? this.getClassesFromNode(mutation.removedNodes)
            : [];
        const allItemClasses = [
          ...rootItemClasses,
          ...addedNodesClasses,
          ...removedNodesClasses,
        ];

        callHandleConditionedContent =
          this.checkClassesForConditionalContent(allItemClasses);
      });

      if (callHandleConditionedContent) {
        this.handleConditionedContent();
      }
    });

    observer.observe(document.querySelector("body"), {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  static checkClassesForConditionalContent(itemClasses) {
    let result = false;

    itemClasses.forEach((itemClass) => {
      if (
        itemClass.startsWith(this.conditioningValueClassPrefix) ||
        itemClass.startsWith(this.conditionedItemClassPrefix)
      ) {
        result = true;
      }
    });

    return result;
  }

  static getClassesFromNode(nodes) {
    const classes = [];
    nodes.forEach((node) => {
      if (node.classList) {
        node.classList.forEach((nodeClass) => {
          classes.push(nodeClass);
        });
      }
    });

    return classes;
  }

  /**
   * Sort an array of objects alphabetically for a specific key.
   * @param array
   * @param key
   */
  static sortAlphabetically(array, key) {
    return array.sort((a, b) => {
      return a[key].localeCompare(b[key], "und", { sensitivity: "base" });
    });
  }

  static getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }

  static deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }

  static insertScript(scriptUrl) {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptUrl;
    script.async = true;
    head.appendChild(script);
  }

  static capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return decodeHTMLEntities;
  })();

  static lowerCaseAndJoin = (str) => {
    return str.toLowerCase().split(" ").join("-");
  };
}
