<template>
  <template v-if="cart?.items?.length">
    <productlist
      categoryid="extrasell"
      :mode="mode"
      :count="count"
      showaddtocart
      :postMountedProducts="extrasellItems"
      :cartMode="true"
    />
  </template>
</template>

<script>
import productlist from "@/components/productlist/ProductList.vue";
import { useCartStore } from "@/store/cart";
import FetchFile from "@/services/fetchFile";
import ExtraSellService from "@/services/extraSell";

export default {
  name: "CartExtraSell",
  components: {
    productlist,
  },
  data() {
    return {
      cart: null,
      loading: false,
      extrasellReference: null,
      extrasellItems: [],
      nbOfItemsInCart: 0,
      lastCheckedCartSignature: null,
    };
  },
  computed: {
    lastCartUpdate() {
      return useCartStore().lastUpdate;
    },
  },
  props: {
    mode: {
      type: String,
      default: "products-grid",
    },
    count: {
      type: Number,
      default: 3,
    },
  },
  watch: {
    lastCartUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.cart = this.getCart();
      },
    },
    cart: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        if (this.lastCheckedCartSignature === useCartStore().calcSignature()) {
          return;
        }
        this.lastCheckedCartSignature = useCartStore().calcSignature();

        const extrasellReference = this.findExtrasellReference();
        if (!extrasellReference) {
          this.extrasellItems = [];
          this.nbOfItemsInCart = this.cart.items.length;
          return;
        }

        if (
          !this.extrasellReference ||
          extrasellReference.choice.id !== this.extrasellReference.choice.id ||
          this.nbOfItemsInCart !== this.cart.items.length
        ) {
          this.$nextTick(() => {
            this.updateExtrasell();
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.cart = this.getCart();
    this.lastCheckedCartSignature = useCartStore().calcSignature();
    this.updateExtrasell();
  },
  methods: {
    getCart() {
      return useCartStore().cart;
    },
    findExtrasellReference() {
      let maxPrice = 0,
        maxPriceElement;
      for (const item of this.cart.items) {
        if (item.choice.unitPrice > maxPrice && item.extrasells) {
          maxPrice = item.choice.unitPrice;
          maxPriceElement = item;
        }
      }

      return maxPriceElement;
    },
    updateExtrasell() {
      this.loading = true;

      if (!this.cart.items.length) {
        this.loading = false;
        return;
      }

      this.extrasellReference = this.findExtrasellReference();
      this.nbOfItemsInCart = this.cart.items.length;

      if (!this.extrasellReference) {
        this.loading = false;
        return;
      }

      if (this.extrasellReference.extrasells.length === 0) {
        this.loading = false;
        return;
      }

      this.extrasellItems = [];
      for (const extra of this.extrasellReference.extrasells) {
        new FetchFile().fetch(
          `/data/products/${extra.productId}.json`,
          ({ data }) => {
            this.extrasellItems = ExtraSellService.getExtraProducts(
              data,
              this.extrasellReference.extrasells,
              this.cart.items,
              this.extrasellItems,
            );
            if (window.globalConfig.totalExtraSellInCart > 0) {
              this.extrasellItems = this.extrasellItems.slice(
                0,
                window.globalConfig.totalExtraSellInCart,
              );
            }
            this.loading = false;
          },
        );
      }
    },
  },
};
</script>
