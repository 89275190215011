import { useSessionStore } from "@/store/session";
import LoggerFront from "@pubfac/logger-front";

export default class Logger extends LoggerFront {
  static getApiBaseUrl() {
    if (process.env.VUE_APP_API_LOG_BASE_URL) {
      return process.env.VUE_APP_API_LOG_BASE_URL; // devs envs
    }
    if (window.globalConfig.siteBaseUrl) {
      const siteBaseUrl = new URL(window.globalConfig.siteBaseUrl);
      const domainName = siteBaseUrl.host.split(".").slice(-2).join(".");
      return "https://logs." + domainName;
    }
  }

  static getSessionId(message, data, logLevel = "") {
    if (!useSessionStore().session) {
      return "";
    }

    return useSessionStore().session.identifier;
  }

  static getApiPayload(logsChunk) {
    return {
      logGroupName: window.globalConfig.logGroupName,
      logInterval:
        window.globalConfig.logGroupName +
        "/" +
        Math.floor(Date.now() / 1000 / 60 / 20), // 20 minutes log interval
      messages: logsChunk,
    };
  }

  static getApiOptions() {
    return {
      headers: {
        "X-API-Key": process.env.VUE_APP_LOG_X_API_KEY,
      },
    };
  }
}
