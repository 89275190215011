<template>
  <div class="product-item-info card h-100">
    <ProductListImage
      :product="product"
      :isProductListFromParent="isProductListFromParent"
    />
    <div class="product-item-details card-body">
      <h3 class="product-item-name">
        <a class="product-item-link" :href="product.url">
          {{ product.name }}
        </a>
      </h3>
      <Ratings
        v-if="showratingstar && !product.hideCustomerReviews"
        mode="skeepers_product__stars"
        :productCodeFromParent="product.code"
      ></Ratings>
      <p class="listing-long-name">
        {{ product.longName }}
      </p>
      <a class="know-more" :href="product.url">
        <span>{{ $t("labels.more") }}</span>
      </a>
    </div>
    <div class="card-footer product-item-details-button">
      <template v-if="getDefaultChoice(product)">
        <Price :product="getDefaultChoice(product)" class="price-container" />
      </template>
      <template v-if="showaddtocart && getDefaultChoice(product)">
        <AddToCart
          :item="fillProductWithChoice(product)"
          :btnlabel="addToCartLabel"
        />
      </template>
      <template v-else-if="showprocessorder && getDefaultChoice(product)">
        <CheckoutButtonProcessOrder
          :item="fillProductWithChoice(product)"
          :processordertext="processordertext"
        />
      </template>
      <a class="know-more" v-else :href="product.url">
        <span>{{ $t("labels.more") }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import Price from "@/components/productlist/subcomponents/Price.vue";
import Ratings from "@/components/product/Ratings.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";
import CheckoutButtonProcessOrder from "@/components/checkout/ButtonProcessOrder.vue";

export default {
  name: "ProductGrid",
  props: {
    product: {
      type: Object,
      default: {},
    },
    categoryid: {
      type: String,
      default: "",
    },
    showaddtocart: {
      type: Boolean,
      default: false,
    },
    addToCartLabel: {
      type: String,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: {
      type: String,
    },
    showratingstar: {
      type: Boolean,
      default: false,
    },
    getDefaultChoice: {
      type: Function,
      required: true,
    },
    fillProductWithChoice: {
      type: Function,
      required: true,
    },
    isProductListFromParent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductListImage,
    Price,
    AddToCart,
    Ratings,
    CheckoutButtonProcessOrder,
  },
};
</script>
