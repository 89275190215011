<template>
  <div class="choice-switch-wrapper" v-if="show">
    <div
      class="switch-choice default-choice-wrapper"
      @click="this.switch(defaultMode)"
      :class="selectedMode === defaultMode ? 'selected' : ''"
    >
      <span class="multiple-times-label">{{ this.defaultChoiceLabel() }}</span>
    </div>
    <div
      class="switch-choice alternative-choice-wrapper"
      @click="this.switch(alternativeMode)"
      :class="selectedMode === alternativeMode ? 'selected' : ''"
    >
      <span class="multiple-times-label">{{
        this.alternativeChoiceLabel()
      }}</span>
    </div>
  </div>
</template>

<script>
import { useCartStore } from "@/store/cart";
import { constants as consts } from "@/constants";

export default {
  data() {
    return {
      selectedMode:
        localStorage.getItem(consts.SELECTED_CHOICES_TYPE) ?? this.defaultMode,
      paymentRecurrence: 0,
      show: false,
      cartItems: [],
    };
  },
  name: "PaymentRecurrenceSwitch",
  computed: {
    defaultMode() {
      return consts.DEFAULT_CHOICES_MODE;
    },
    alternativeMode() {
      return consts.ALTERNATIVE_CHOICES_MODE;
    },
  },
  mounted() {
    this.cartItems = useCartStore().cart.items;
    this.show = this.showOrNot();
    localStorage.setItem(consts.SELECTED_CHOICES_TYPE, this.selectedMode);
  },
  methods: {
    defaultChoiceLabel() {
      return `${this.$t("labels.multiplePayment")} 1 ${this.$t("labels.times")}`;
    },
    alternativeChoiceLabel() {
      if (window.globalConfig?.alternativeChoiceLabel) {
        return window.globalConfig?.alternativeChoiceLabel;
      }
      return `${this.$t("labels.multiplePayment")} ${this.$t("labels.several")} ${this.$t("labels.times")}`;
    },
    switch(selected) {
      if (selected === this.alternativeMode || selected === this.defaultMode) {
        this.selectedMode = selected;
        this.toggleAllChoices(selected);
      }

      console.log("Cart items", this.cartItems);
    },
    toggleAllChoices(selected) {
      this.cartItems.forEach((product) => {
        if (selected === this.alternativeMode && product.choice.default) {
          product.choice = product.choices.find(
            (choice) => choice.isAlternativeChoice && !choice.default,
          );
        } else if (
          selected === this.defaultMode &&
          product.choice.isAlternativeChoice
        ) {
          product.choice = product.choices.find(
            (choice) => choice.default && !choice.isAlternativeChoice,
          );
        }
      });
    },
    showOrNot() {
      const productChoices = {};
      let show = false;

      for (const product of this.cartItems) {
        if (!product.choice.default && !product.choice.isAlternativeChoice) {
          this.selectedMode = this.defaultMode;
          return false;
        }

        productChoices[product.id] = {
          defaultChoice: product.choices.find(
            (choice) => choice.default && !choice.isAlternativeChoice,
          ),
          alternativeChoice: product.choices.find(
            (choice) => choice.isAlternativeChoice && !choice.default,
          ),
        };
      }

      for (const [productId, choices] of Object.entries(productChoices)) {
        if (choices.defaultChoice && choices.alternativeChoice) {
          show = true;
        } else {
          this.selectedMode = this.defaultMode;
          return false;
        }
      }

      if (!show) {
        this.selectedMode = this.defaultMode;
      }
      return show;
    },
  },
  watch: {
    cartItems: {
      handler() {
        this.show = this.showOrNot();
        this.toggleAllChoices(this.selectedMode);
      },
      deep: true,
    },
    selectedMode() {
      localStorage.setItem(consts.SELECTED_CHOICES_TYPE, this.selectedMode);
    },
  },
};
</script>
