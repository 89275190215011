import { defineStore } from "pinia";
import { constants } from "@/constants";
import MultiLocalStorage from "@/services/multiLocalStorage";

export const useSessionStore = defineStore("session", {
  state: () => ({
    _session: null,
  }),
  getters: {
    session: (state) => {
      if (!state._session) {
        const session = MultiLocalStorage.get(
          constants.ECOM_SESSION,
          window?.globalConfig?.domainId,
        );
        state._session = session?.identifier ? session : null;
      }

      return state._session;
    },
  },
  actions: {
    setSession(session) {
      this._session = session;
      MultiLocalStorage.set(
        constants.ECOM_SESSION,
        window?.globalConfig?.domainId,
        session,
      );
    },
    emptySession() {
      this._session = null;
      MultiLocalStorage.set(
        constants.ECOM_SESSION,
        window?.globalConfig?.domainId,
        {},
      );
    },
  },
});
