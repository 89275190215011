import { defineStore } from "pinia";
import { constants } from "@/constants";
import Navigation from "@/services/navigation";
import MultiLocalStorage from "@/services/multiLocalStorage";

export const useTrackingStore = defineStore("tracking", {
  state: () => ({
    _urlParams: null,
  }),
  getters: {
    urlParams: (state) => {
      if (!state._urlParams) {
        const urlsParamsFromStorage = MultiLocalStorage.get(
          constants.URL_PARAMS,
          window?.globalConfig?.domainId,
        );
        const urlParamsBeforeMerge = urlsParamsFromStorage
          ? urlsParamsFromStorage
          : {};

        const queryParams = Navigation.getQueryParams();

        let testLevel = queryParams.get("testLevel");
        if (
          queryParams.get("isBat") &&
          (queryParams.get("isBat") === "1" ||
            queryParams.get("isBat") === "true")
        ) {
          testLevel = "salesforce_only";
        }

        if (testLevel !== null) {
          queryParams.set("testLevel", testLevel);
        }

        for (const [key, value] of queryParams) {
          if (
            value === "null" ||
            (key === "coupon" && window.location.pathname === "/confirmation")
          ) {
            delete urlParamsBeforeMerge[key];
          } else {
            urlParamsBeforeMerge[key] = value;
          }
        }

        state._urlParams = urlParamsBeforeMerge;

        MultiLocalStorage.set(
          constants.URL_PARAMS,
          window?.globalConfig?.domainId,
          urlParamsBeforeMerge,
        );
      }

      return state._urlParams;
    },
  },
  actions: {
    mergeTrackingData(urlParams) {
      const urlParamsBeforeMerge = this.urlParams;
      for (const [key, value] of Object.entries(urlParams)) {
        if (value === "null") {
          delete urlParamsBeforeMerge[key];
        } else {
          urlParamsBeforeMerge[key] = value;
        }
      }

      MultiLocalStorage.set(
        constants.URL_PARAMS,
        window?.globalConfig?.domainId,
        urlParamsBeforeMerge,
      );
    },
  },
});
