<template>
  <div v-if="bclChoices.length" class="product-choices bcl-choices">
    <div
      class="product-choices-option bcl-options"
      v-for="choice in bclChoices"
    >
      <input
        type="radio"
        :name="choice.title"
        :value="choice.id"
        :id="choice.id"
        v-model="selectedChoice"
      />
      <label :for="choice.id">
        <span class="choice-name">{{ choice.description }}</span>
        <span
          class="price-container choice-price price-per-unit"
          v-if="showpriceperunit"
        >
          <span class="price-per-unit">
            <span class="per-unit-label"
              >{{ $t("labels.pricePer") }} {{ choice.unitName }}:
            </span>
            <span class="amount">{{
              formatPrice(choice.unitPrice / choice.numberOfUnit)
            }}</span>
            <span class="currency">&euro;</span>
          </span>
        </span>
        <span class="promotion-label-container" v-if="choice.promotionLabel">
          <span class="promotion-label">
            {{ choice.promotionLabel }}
          </span>
        </span>
        <span class="price-container choice-price">
          <span v-if="choice.oldPrice" class="old-price-wrapper">
            <span class="old-price">
              <span class="amount">{{ formatPrice(choice.oldPrice) }}</span>
              <span class="currency">&euro;</span>
            </span>
          </span>
          <span
            class="price"
            v-if="
              !choice.isSingleProduct &&
              choice.hasOwnProperty('startingPrice') &&
              choice.startingPriceDuration > 0
            "
          >
            <span class="starting-offer">
              <span class="amount">{{
                formatPrice(choice.startingPrice)
              }}</span>
              <span class="currency">&euro;</span>
              <span class="starting-offer-detail">{{
                displayDuration(choice.startingPriceDuration, true)
              }}</span>
            </span>
            <br />
            <span class="after-starting-offer">
              <span class="after-starting-offer-details"
                >{{ $t("labels.then") }}&nbsp;</span
              >
              <template v-if="choice.defaultPrice !== 0">
                <span class="amount">{{
                  formatPrice(choice.defaultPrice)
                }}</span>
                <span class="currency">&euro;</span>
                <span class="after-starting-offer-details">
                  / {{ displayDuration(choice.renewalTermLength) }}</span
                >
              </template>
              <template v-else>
                <span class="after-starting-offer-details">{{
                  choice.freeLabel
                }}</span>
              </template>
            </span>
          </span>
          <span class="price" v-else>
            <span class="amount">{{ formatPrice(choice.unitPrice) }}</span>
            <span class="currency">&euro;</span>
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { useDraftStore } from "@/store/draft.js";
import Utils from "@/services/utils";
import Price from "@/services/price";

export default {
  name: "ProductBclChoices",
  props: {
    showpriceperunit: Boolean,
  },
  data() {
    return {
      allChoices: [],
      bclChoices: [],
      selectedChoice: null,
    };
  },
  computed: {
    storeSelectedChoice() {
      return useDraftStore().selectedChoice;
    },
  },
  mounted() {
    this.allChoices = window.productDetails.choices;

    this.bclChoices = window.productDetails.choices.filter(
      (choice) => choice.bcl === true && choice.show === true,
    );

    this.bclChoices.sort((c1, c2) => {
      return c1.numberOfUnit - c2.numberOfUnit;
    });

    const defaultChoice = window.productDetails.choices.find(
      (choice) => choice.default,
    );
    if (defaultChoice) {
      this.selectedChoice = defaultChoice.id;
    }
  },
  watch: {
    selectedChoice: function (newVal, oldVal) {
      if (this.storeSelectedChoice !== newVal) {
        useDraftStore().selectChoice(newVal);
      }
    },
    storeSelectedChoice: function (newVal, oldVal) {
      if (this.selectedChoice !== newVal) {
        this.selectedChoice = newVal;
      }
    },
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
    formatPrice(price) {
      return Price.format(price);
    },
  },
};
</script>
