<template>
  <template
    v-if="currentProduct && currentProduct.startDate && currentProduct.endDate"
  >
    <div class="progress-container">
      <div :class="progressBarColor" :style="`width: ${percentLeft}%;`"></div>
    </div>
    <div class="stock-info">
      <span class="stock-percentage">
        <template v-if="percentLeft <= 0">
          <strong>{{ $t("labels.soldOut") }}</strong>
        </template>
        <template v-else>
          <strong>{{ percentLeft }}%</strong> {{ $t("labels.remainingStock") }}
        </template>
      </span>
      <span class="countdown" v-html="timeLeft"></span>
    </div>
  </template>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      currentProduct: {},
      percentLeft: "",
      timeLeft: "",
      intervalPercentageLeft: null,
      intervalTimeLeft: null,
    };
  },
  mounted() {
    this.currentProduct = window.productDetails || this.product || {};
    if (
      this.currentProduct &&
      this.currentProduct.endDate &&
      this.currentProduct.startDate
    ) {
      this.calculateTimeLeft();
      this.calculatePercentLeft();
      this.intervalTimeLeft = setInterval(this.calculateTimeLeft, 1000);
      this.intervalPercentageLeft = setInterval(
        this.calculatePercentLeft,
        1000,
      );
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalTimeLeft);
    clearInterval(this.intervalPercentageLeft);
  },
  computed: {
    tdays() {
      return this.$t("labels.days");
    },
    tday() {
      return this.$t("labels.day");
    },
    progressBarColor() {
      const percentage = this.percentLeft;
      if (percentage <= 20) {
        return "progress-bar progress-bar-danger";
      } else if (percentage <= 60) {
        return "progress-bar progress-bar-warning";
      } else {
        return "progress-bar progress-bar-success";
      }
    },
  },
  methods: {
    calculatePercentLeft() {
      const now = Date.now();
      const startDate = parseInt(this.currentProduct.startDate);
      const endDate = parseInt(this.currentProduct.endDate);
      const total = endDate - startDate;
      let percentage = ((now - startDate) / total) * 100;

      // Clamp the percentage between 0 and 100
      percentage = Math.floor(Math.min(Math.max(percentage, 0), 100));

      // If the current date is before the start date or after the end date, set the bar to 0%
      if (now < startDate || now > endDate) {
        percentage = 100;
      }

      this.percentLeft = isNaN(percentage) ? 0 : 100 - percentage;
    },
    calculateTimeLeft() {
      const now = Date.now();
      const endDate = parseInt(this.currentProduct.endDate);
      const total = endDate - now;
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((total % (1000 * 60)) / 1000);
      const hoursPadded = hours.toString().padStart(2, "0");
      const minutesPadded = minutes.toString().padStart(2, "0");
      const secondsPadded = seconds.toString().padStart(2, "0");

      if (total <= 0) {
        this.timeLeft = this.$t("labels.ended");
        return;
      }

      const time = `<strong>${hoursPadded}:${minutesPadded}:${secondsPadded}</strong>`;
      if (days < 1) {
        this.timeLeft = time;
        return;
      }

      this.timeLeft = `<strong>${days}</strong> ${days === 1 ? this.tday : this.tdays} ${time}`;
    },
  },
};
</script>
