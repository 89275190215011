import axios from "axios";
import { useCartStore } from "@/store/cart";
import { constants } from "@/constants";
import CartService from "@/services/cart";
import Logger from "@/services/logger";
import MultiLocalStorage from "@/services/multiLocalStorage";

export default class CouponService {
  static getApiBaseUrl() {
    if (process.env.VUE_APP_ORION_API_BASE_URL) {
      return process.env.VUE_APP_ORION_API_BASE_URL; // devs envs
    }
    if (window.globalConfig.siteBaseUrl) {
      const siteBaseUrl = new URL(window.globalConfig.siteBaseUrl);
      const domainName = siteBaseUrl.host.split(".").slice(-2).join(".");
      return "https://orionv2." + domainName;
    }
  }

  static checkCoupons(
    minifiedCart,
    isFromCheckout = true,
    isFromProductPage = false,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.getApiBaseUrl()}/order`, {
          cart: minifiedCart,
          domainId: window.globalConfig.domainId,
        })
        .then(
          (response) => {
            if (!isFromProductPage) {
              this.applyCoupons(response.data.cart);
            }
            Logger.log("applied coupon", response.data);

            resolve(response);
          },
          (error) => {
            if (isFromCheckout) {
              console.error(error);
              Logger.error("Error while checking coupon", error);
            }
            reject(error);
          },
        );
    });
  }

  static checkCouponOnPage(minifiedCart) {
    return new Promise((resolve, reject) => {
      const params = {
        cart: encodeURIComponent(JSON.stringify(minifiedCart)),
        domainId: window.globalConfig.domainId,
      };
      axios
        .get(`${this.getApiBaseUrl()}/order`, {
          params,
        })
        .then(
          (response) => {
            Logger.log("applied coupon", response.data);

            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  static getCouponFromLocalStorage(couponCode, choiceId) {
    const coupons = MultiLocalStorage.get(
      constants.ECOM_COUPONS,
      window?.globalConfig?.domainId,
      true,
    );
    const result = {
      data: null,
      keyExists: false,
    };
    if (coupons) {
      result.data = coupons[`${couponCode}-${choiceId}`];
      if (Object.keys(coupons).includes(`${couponCode}-${choiceId}`)) {
        result.keyExists = true;
      }
    }
    return result;
  }

  static setCouponInLocalStorage(couponCode, choiceId, data) {
    MultiLocalStorage.set(
      constants.ECOM_COUPONS,
      window?.globalConfig?.domainId,
      {
        [`${couponCode}-${choiceId}`]: data,
      },
      true,
      constants.COUPON_TTL,
    );
  }

  static applyCoupons(recalculatedCart) {
    CartService.updateCartLoading(true);

    const cart = useCartStore().cart;
    const coupons = Object.values(recalculatedCart.coupons);
    const recalculatedItems = recalculatedCart.items;

    const choiceAttributes = [
      "unitPrice",
      "oldPrice",
      "defaultPrice",
      "shippingPrice",
      "discountedAmount",
    ];
    const cartItemAttributes = ["qty", "defaultSubtotal", "subtotal"];

    for (const recalculatedItem of recalculatedItems) {
      const currentCartItem = cart.items.find(
        (item) =>
          item.choice.id === recalculatedItem.choice.id &&
          item.isFromCoupon === recalculatedItem.isFromCoupon,
      );

      if (currentCartItem && !recalculatedItem.isFromCoupon) {
        for (const choiceAttribute of choiceAttributes) {
          if (
            recalculatedItem.choice[choiceAttribute] &&
            recalculatedItem.choice[choiceAttribute] !==
              currentCartItem.choice[choiceAttribute]
          ) {
            currentCartItem.choice[choiceAttribute] =
              recalculatedItem.choice[choiceAttribute];
          }
        }
        for (const cartItemAttribute of cartItemAttributes) {
          if (
            recalculatedItem[cartItemAttribute] &&
            recalculatedItem[cartItemAttribute] !==
              currentCartItem[cartItemAttribute]
          ) {
            currentCartItem[cartItemAttribute] =
              recalculatedItem[cartItemAttribute];
          }
        }
      } else if (!currentCartItem && recalculatedItem.isFromCoupon) {
        CartService.addToCart(recalculatedItem);
      }
    }

    const globalAttributes = [
      "freeShippingProgression",
      "shippingPrice",
      "subtotal",
      "defaultSubtotal",
      "total",
    ];
    for (const globalAttribute of globalAttributes) {
      if (
        (recalculatedCart[globalAttribute] ||
          recalculatedCart[globalAttribute] === 0) &&
        recalculatedCart[globalAttribute] !== cart[globalAttribute]
      ) {
        cart[globalAttribute] = recalculatedCart[globalAttribute];
      }
    }

    for (const coupon of coupons) {
      if (coupon.isValid === true) {
        if (!cart.coupons) {
          cart.coupons = {};
        }
        cart.coupons[coupon.code] = coupon;
        if (coupon.discountStrategy === "FreeFees") {
          cart.shippingPrice = recalculatedCart.shippingPrice;
        }
      } else if (
        coupon.isValid === false &&
        cart.hasOwnProperty("coupons") &&
        cart.coupons[coupon.code]
      ) {
        if (
          cart.coupons[coupon.code].discountStrategy ===
          "ProductOfferedByAmount"
        ) {
          const itemToDelete = cart.items.find(
            (item) => item.isFromCoupon === coupon.code,
          );
          if (itemToDelete) {
            CartService.deleteItem(itemToDelete);
          }
        }
        delete cart.coupons[coupon.code];
      }
    }

    useCartStore().saveCart();
    document.dispatchEvent(
      new CustomEvent("cart-updated", { detail: useCartStore().cart }),
    );
    CartService.updateCartLoading(false);
  }

  static deleteCoupon(couponCode) {
    Logger.log("deleteCoupon", couponCode);

    const cart = useCartStore().cart;
    let couponToDelete = {};

    // Remove specific coupon
    for (const cartCouponCode in cart.coupons) {
      if (cartCouponCode === couponCode) {
        couponToDelete = { ...cart.coupons[cartCouponCode] };
        delete cart.coupons[couponCode];
      }
    }

    // Fallback all prices to original prices
    for (const item of cart.items) {
      if (
        item.choice.hasOwnProperty("startingPrice") &&
        item.choice?.startingPriceDuration > 0
      ) {
        item.choice.unitPrice = item.choice.startingPrice;
        delete item.choice.oldPrice;
        continue;
      }

      if (item.choice.defaultPrice) {
        item.choice.unitPrice = item.choice.defaultPrice;
        delete item.choice.oldPrice;
      }
    }

    CartService.updateCart();
    // Delete free product after coupon deletion to avoid free product to be added again
    if (
      Object.keys(couponToDelete).length &&
      couponToDelete.discountStrategy === "ProductOfferedByAmount"
    ) {
      const itemToDelete = cart.items.find(
        (item) => item.isFromCoupon === couponToDelete.code,
      );
      if (itemToDelete) {
        CartService.deleteItem(itemToDelete);
      }
    }
  }

  static addCouponInMinifiedCart(couponCode) {
    const minifiedCart = CartService.minifyCart();
    const couponsCodes = minifiedCart.coupons
      ? Object.keys(minifiedCart.coupons)
      : [];
    if (!couponsCodes.includes(couponCode)) {
      if (!minifiedCart.coupons) {
        minifiedCart.coupons = {};
      }
      minifiedCart.coupons[couponCode] = { code: couponCode };
    }

    return minifiedCart;
  }
}
