import { defineStore } from "pinia";
import { constants } from "@/constants";
import { useCartStore } from "@/store/cart";
import { useSessionStore } from "@/store/session";
import MultiLocalStorage from "@/services/multiLocalStorage";

export const useOrderHistoryStore = defineStore("orderHistory", {
  state: () => ({
    _lastUpdate: null,
    _orderHistory: [],
  }),
  getters: {
    orderHistory: (state) => {
      MultiLocalStorage.checkCleanOrderHistory();

      if (state._orderHistory.length === 0) {
        const orderHistory = MultiLocalStorage.get(
          constants.ECOM_ORDER_HISTORY,
          window?.globalConfig?.domainId,
        );
        return orderHistory ? orderHistory : [];
      }

      return state._orderHistory;
    },
    lastUpdate: (state) => state._lastUpdate,
  },
  actions: {
    addStepToHistory() {
      MultiLocalStorage.checkCleanOrderHistory();

      if (this.orderHistory.length > 0) {
        const lastHistoryItem = this.orderHistory[this.orderHistory.length - 1];
        if (lastHistoryItem.step) {
          lastHistoryItem.step += 1;
        } else {
          lastHistoryItem.step = 1;
        }
      }

      this._orderHistory = this.orderHistory;

      MultiLocalStorage.set(
        constants.ECOM_ORDER_HISTORY,
        window?.globalConfig?.domainId,
        this.orderHistory,
      );
    },
    addOrderToHistory(cart, order, step) {
      MultiLocalStorage.checkCleanOrderHistory();

      if (!order.sessionIdentifier) {
        order.sessionIdentifier = useSessionStore().session.identifier;
      }

      if (this.orderHistory.length > 0 && cart && order && !step) {
        const lastHistoryItem = this.orderHistory.slice(-1)[0];
        if (
          order.orderType === "crosssell" &&
          lastHistoryItem.order.externalOrderId ===
            order.previousExternalOrderId &&
          lastHistoryItem.order.externalOrderId !== order.externalOrderId &&
          lastHistoryItem.order.sessionIdentifier === order.sessionIdentifier
        ) {
          // We increase step if within the same session, on the same orderPage OR shop, we have 2 different consecutive orders
          step = lastHistoryItem.step ? lastHistoryItem.step + 1 : 1;
        }
      }
      if (!step) {
        step = 1;
      }

      const existingHistoryItem = this.orderHistory.find(
        (i) => i.order.externalOrderId === order.externalOrderId,
      );

      if (!existingHistoryItem) {
        this.orderHistory.push({ order, cart, step });
      } else {
        Object.assign(existingHistoryItem, { order, cart });
      }

      this.orderHistory.splice(0, this.orderHistory.length - 4); // limiting history to the 4 last orders

      this._orderHistory = this.orderHistory;

      MultiLocalStorage.set(
        constants.ECOM_ORDER_HISTORY,
        window?.globalConfig?.domainId,
        this.orderHistory,
      );

      this._lastUpdate = new Date();
    },
  },
});
