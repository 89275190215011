<template>
  <div v-if="argument3" v-html="renderHTML(argument3)"></div>
</template>

<script>
export default {
  name: "ProductArgument3",
  data() {
    return {
      argument3: null,
    };
  },
  mounted() {
    this.argument3 = window.productDetails.argument3;
  },
};
</script>
