<template>
  <div class="row justify-content-center text-center">
    <div
      class="product-item col-sm-6"
      v-for="product in products"
      v-bind:data-product-id="product.id"
    >
      <div class="product-item-info card h-100">
        <ProductListImage :product="product" :isProductListFromParent="true" />
        <div class="product-item-details card-body">
          <h3 class="product-item-name">
            <a class="product-item-link" :href="product.url">
              {{ product.name }}
            </a>
          </h3>
        </div>
        <div class="card-footer product-item-details-button">
          <template v-if="getDefaultChoice(product)">
            <Price
              :product="getDefaultChoice(product)"
              class="price-container"
            />
          </template>
          <template v-if="showaddtocart && getDefaultChoice(product)">
            <AddToCart
              :item="fillProductWithChoice(product)"
              :btnlabel="addToCartLabel"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import Utils from "@/services/utils";
import Price from "@/components/productlist/subcomponents/Price.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";

export default {
  name: "ProductListGridCart",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryid: {
      type: String,
      default: "",
    },
    showaddtocart: {
      type: Boolean,
      default: false,
    },
    addToCartLabel: {
      type: String,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: {
      type: String,
    },
    getDefaultChoice: {
      type: Function,
      required: true,
    },
    fillProductWithChoice: {
      type: Function,
      required: true,
    },
  },
  components: {
    AddToCart,
    Price,
    ProductListImage,
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
  },
};
</script>
