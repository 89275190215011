<template></template>
<script>
import { constants } from "@/constants";
import Palyance from "@/services/palyance";
import Redirect from "@/services/redirect";
import Curiosity from "@/services/curiosity";
import { useOrderHistoryStore } from "@/store/orderHistory";
import AlreadyOrderedProducts from "@/services/alreadyOrderedProducts";
import { useDraftStore } from "@/store/draft";

export default {
  name: "WaitingPayment",
  mounted() {
    this.main();
  },
  computed: {
    billingEmail() {
      return useOrderHistoryStore().orderHistory.slice(-1)[0].order
        .billingEmail;
    },
  },
  methods: {
    main() {
      const paramPlusData = Redirect.getParamPlusData();
      const queryParams = Redirect.checkAndGetQueryParams(paramPlusData);

      if (!paramPlusData || !paramPlusData.orderId) {
        Redirect.redirectToError(paramPlusData);
        return;
      }

      if (
        (paramPlusData.action === "recapture" &&
          queryParams.authorizationId === undefined) ||
        paramPlusData.paymentType === "sepa"
      ) {
        Curiosity.addCoregs(this.billingEmail, paramPlusData)
          .finally(() => {
            AlreadyOrderedProducts.updateAfterPayment();
            useDraftStore().updateHas1ClickOptOut(false);
            Redirect.redirectToConfirmation(paramPlusData);
          })
          .catch((error) => {
            console.error(error);
          });
        return;
      }

      // Read query params to find authorizationId to get Ogone status
      if (!queryParams.authorizationId) {
        console.error(
          `No authorizationId found, aborting : paramPlusData: ${JSON.stringify(paramPlusData)}, queryParams: ${JSON.stringify(queryParams)}, orderId: ${paramPlusData.orderId}`,
        );
        Redirect.redirectToError(paramPlusData);

        return;
      }

      const startedAt = new Date().getTime();
      const intervalId = setInterval(() => {
        this.getOgoneStatus(
          queryParams.authorizationId,
          paramPlusData.orderId,
          (data) => {
            if (
              data.status === "NOT_FOUND" ||
              data.status === "FAIL" ||
              data.status === "UNK"
            ) {
              clearInterval(intervalId);
              Redirect.redirectToError(paramPlusData);
              return;
            }

            const now = new Date().getTime();
            if (now - startedAt > constants.AUTHORIZATION_STATUS_TIMEOUT) {
              clearInterval(intervalId);
              Redirect.redirectToError(paramPlusData);
              return;
            }

            // still waiting for more significant status, so re-loop
            if (data.status === "3DS") {
              return;
            }

            if (data.status === "OK") {
              clearInterval(intervalId);
              Curiosity.addCoregs(this.billingEmail, paramPlusData)
                .finally(() => {
                  AlreadyOrderedProducts.updateAfterPayment();
                  useDraftStore().updateHas1ClickOptOut(false);
                  // Redirect to confirmation page
                  Redirect.redirectToConfirmation(paramPlusData);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          },
        );
      }, 5000);
    },
    getOgoneStatus(authorizationId, orderId, callback) {
      Palyance.getOgoneStatus(authorizationId)
        .then((data) => {
          console.log(JSON.stringify(data), orderId);
          callback(data);
        })
        .catch((error) => {
          const message = `Error while getting ogone status : ${error.toString()}, authorizationId: ${authorizationId}, orderId : ${orderId}`;
          console.error(message);
          callback(null, new Error(message));
        });
    },
  },
};
</script>
