import { constants } from "@/constants";
import { useTrackingStore } from "@/store/tracking";
import { useSessionStore } from "@/store/session";

export default class Redirect {
  static getParamPlusData() {
    let paramPlusData = localStorage.getItem(constants.PARAM_PLUS);
    try {
      paramPlusData = JSON.parse(paramPlusData);
    } catch (error) {
      console.error(error, paramPlusData);
    }
    return paramPlusData;
  }

  static checkAndGetQueryParams(paramPlusData) {
    if (useTrackingStore().urlParams.NCError !== "undefined") {
      console.error(
        `Ogone error landing page, ${JSON.stringify(useTrackingStore().urlParams)}`,
        paramPlusData === null ? "noOrderId" : paramPlusData.orderId,
        useTrackingStore().urlParams.NCError,
      );
    }
    return useTrackingStore().urlParams;
  }

  static buildQueryStringFromParamPlus(paramPlusData) {
    const excludeParams = ["orderId", "paymentType", "action"];

    return Object.keys(paramPlusData)
      .filter((key) => {
        return !excludeParams.includes(key);
      })
      .map((key) => `${key}=${paramPlusData[key]}`)
      .join("&");
  }

  static redirectToError(paramPlusData) {
    const errorBaseUrl = `${window.location.origin}/error`;
    const queryString = this.buildQueryStringFromParamPlus(paramPlusData);
    window.location.href = `${errorBaseUrl}${queryString ? "?" + queryString : ""}`;
  }

  static redirectToConfirmation(paramPlusData) {
    const confirmationPage = window.globalConfig.confirmationPage
      ? window.globalConfig.confirmationPage
      : "/confirmation";
    const confirmationBaseUrl = `${window.location.origin}${confirmationPage}`;
    const queryString = this.buildQueryStringFromParamPlus(paramPlusData);
    window.location.href = `${confirmationBaseUrl}${queryString ? "?" + queryString : ""}`;
  }

  static redirectToCheckout(paramPlusData) {
    const confirmationBaseUrl = `${window.location.origin}/checkout`;
    const queryString = this.buildQueryStringFromParamPlus(paramPlusData);
    window.location.href = `${confirmationBaseUrl}${queryString ? "?" + queryString : ""}`;
  }
}
