import axios from "axios";

export default class Pg {
  static getApiBaseUrl() {
    if (process.env.VUE_APP_PG_API_BASE_URL) {
      return process.env.VUE_APP_PG_API_BASE_URL;
    }
    if (window.globalConfig.siteBaseUrl) {
      const siteBaseUrl = new URL(window.globalConfig.siteBaseUrl);
      const domainName = siteBaseUrl.host.split(".").slice(-2).join(".");
      return "https://pgsfv2." + domainName;
    }
  }

  static getPrefillData(polarisId, affiliateId) {
    return new Promise((resolve, reject) => {
      try {
        const parameters = {
          polarisId,
          affiliateId,
        };

        const url = `${this.getApiBaseUrl()}/processfront?action=contact&action_infos=${encodeURIComponent(JSON.stringify(parameters))}`;

        axios.get(url).then(
          (response) => {
            if (!response.status || response.status >= 300 || !response.data) {
              return reject(
                new Error(
                  `processfront status failed ${JSON.stringify(response)}`,
                ),
              );
            }

            if (response.data.body.code <= 204) {
              resolve(this.transformContactData(response.data.body.data));
            } else {
              reject(new Error(response.data.body.message));
            }
          },
          (error) => {
            reject(new Error(error));
          },
        );
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  static getActiveProducts({ contactPolarisId, affiliateId }) {
    return new Promise((resolve, reject) => {
      try {
        const parameters = {
          salesforceId: contactPolarisId,
          affiliateId: affiliateId,
        };

        const url = `${this.getApiBaseUrl()}/processfront?action=activeProducts&action_infos=${encodeURIComponent(JSON.stringify(parameters))}`;
        axios.get(url).then(
          (response) => {
            if (!response.status || response.status >= 300 || !response.data) {
              return reject(
                new Error(
                  `processfront status failed ${JSON.stringify(response)}`,
                ),
              );
            }

            if (response.data.body.code <= 204) {
              resolve(
                response.data.body.data === null ? [] : response.data.body.data,
              );
            } else {
              reject(new Error(response.data.body.message));
            }
          },
          (error) => {
            reject(new Error(error));
          },
        );
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  static transformContactData(data) {
    const response = {};

    if (!data || !data[0] || !data[0].contact) {
      return response;
    }

    const contactData = data[0].contact;

    return {
      billingEmail: contactData.Email,
      billingGender: contactData.Gender__c,
      billingFirstName: contactData.FirstName,
      billingLastName: contactData.LastName,
      billingPhoneNumber: contactData.Phone,
      billingStreet3: contactData.BillingStreet3__c,
      billingStreet4: contactData.BillingStreet4__c,
      billingStreet1: contactData.BillingStreet1__c,
      billingStreet2: contactData.BillingStreet2__c,
      billingZipCode: contactData.BillingZipCode__c,
      billingCity: contactData.BillingCity__c,
      billingCountry: contactData.BillingCountry__c,
      emailEncrypted: contactData.EmailEncrypted__c,
      shippingCity: contactData.ShippingCity__c,
      shippingCountry: contactData.ShippingCountry__c,
      shippingStreet1: contactData.ShippingStreet1__c,
      shippingStreet2: contactData.ShippingStreet2__c,
      shippingStreet3: contactData.ShippingStreet3__c,
      shippingStreet4: contactData.ShippingStreet4__c,
      shippingZipCode: contactData.ShippingZipCode__c,
      oneClickCC: contactData.OneClickCC__c,
      oneClickSEPA: contactData.OneclickSEPA__c,
      doNotSend1CLICKOffers: contactData.DoNotSend1CLICKOffers__c,
    };
  }
}
