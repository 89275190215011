<template>{{ name }}</template>

<script>
export default {
  name: "CategoryName",
  props: [],
  data() {
    return {
      name: "",
    };
  },
  mounted() {
    this.name = window.category.name;
  },
};
</script>
