import { useSessionStore } from "@/store/session";
import Utils from "@/services/utils";

export default class SessionService {
  static generateNewSession(pageData) {
    let sessionId = null,
      pageHistory = [],
      sessionCreatedAt = null;
    if (!useSessionStore().session) {
      sessionId = Utils.generateUID();
      sessionCreatedAt = new Date().getTime();
    } else {
      sessionId = useSessionStore().session.identifier;
      sessionCreatedAt = useSessionStore().session.createdAt;

      if (
        sessionCreatedAt &&
        new Date().getTime() - sessionCreatedAt > 1000 * 60 * 60 * 24
      ) {
        // session is reset each day
        this.emptySession();
        sessionId = Utils.generateUID();
        sessionCreatedAt = new Date().getTime();
      }
    }

    if (useSessionStore().session) {
      pageHistory = useSessionStore().session.history;
      if (!pageHistory) {
        pageHistory = [];
      }
    }
    if (
      pageHistory.length &&
      pageHistory[pageHistory.length - 1].pathname === window.location.pathname
    ) {
      return; // same page as previous (reload)
    }

    pageHistory.push({
      url: window.location.href,
      pathname: window.location.pathname,
      timestamp: new Date().getTime(),
      ...pageData,
    });

    useSessionStore().setSession({
      identifier: sessionId,
      history: pageHistory,
      createdAt: sessionCreatedAt,
    });
  }

  static emptySession() {
    useSessionStore().emptySession();
  }
}
