<template>
  <div
    class="product-item"
    v-for="product in products"
    v-bind:data-product-id="product.id"
  >
    <div class="product-item-info row align-items-center">
      <ProductListImage
        :product="product"
        is-from-list
        :isProductListFromParent="true"
      />
      <div class="product-item-details col-sm-5">
        <h3 class="product-item-name">
          <a :href="product.url">{{ product.name }}</a>
        </h3>
      </div>
      <div class="price-container col-sm-5">
        <span class="price-wrapper">
          <span class="price">
            <span class="amount">{{
              formatPrice(getDefaultChoice(product).unitPrice)
            }}</span>
            <span class="currency">&euro;</span>
          </span>
        </span>
        <template v-if="showaddtocart && getDefaultChoice(product)">
          <AddToCart
            :item="fillProductWithChoice(product)"
            :btnlabel="addToCartLabel"
          />
        </template>
        <a v-else :href="product.url">
          <span class="goto-product">{{ $t("labels.more") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";
import Utils from "@/services/utils";
import Price from "@/services/price";

export default {
  name: "ProductListCart",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryid: {
      type: String,
      default: "",
    },
    showaddtocart: {
      type: Boolean,
      default: false,
    },
    addToCartLabel: {
      type: String,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: {
      type: String,
    },
    getDefaultChoice: {
      type: Function,
      required: true,
    },
    fillProductWithChoice: {
      type: Function,
      required: true,
    },
  },
  components: {
    AddToCart,
    ProductListImage,
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
    formatPrice(price) {
      return Price.format(price);
    },
  },
};
</script>
