<template>
  <div class="product-item-info" @click="redirectToProduct">
    <div v-if="promotionLabel" class="promotion-label">
      {{ promotionLabel }}
    </div>
    <ProductListImage
      :product="product"
      :isProductListFromParent="isProductListFromParent"
    />
    <div class="product-item-wrapper">
      <div class="title">{{ product.featuredProductName || product.name }}</div>
      <div class="listing-long-name">{{ product.longName }}</div>
      <div class="price">{{ product.startingPrice }}</div>
      <ProgressBar :product="product" />
      <div class="cta">
        <button>
          {{ tbutton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/product/ProgressBar.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";

export default {
  name: "ProductVP",
  components: {
    ProductListImage,
    ProgressBar,
  },
  props: {
    product: {
      type: Object,
    },
    textInButton: {
      type: String,
    },
    isProductListFromParent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tbutton() {
      return this.textInButton || this.$t("labels.accessToOffer");
    },
    promotionLabel() {
      const defaultChoice = this.product.choices.find(
        (item) => item.default && item.show,
      );
      return defaultChoice.promotionLabel;
    },
  },
  methods: {
    redirectToProduct() {
      window.location.href = this.product.url;
    },
  },
};
</script>
