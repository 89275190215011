<template>
  <img v-if="url" :src="url" :alt="categoryName + ' category image'" />
</template>

<script>
export default {
  name: "CategoryImage",
  data() {
    return {
      categoryName: "",
      url: "",
    };
  },
  props: {
    type: {
      type: String,
    },
  },
  mounted() {
    this.url =
      this.type === "image" ? window.category?.image : window.category?.icon;
    this.categoryName = window.category?.name;
  },
};
</script>
