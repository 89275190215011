import { useDraftStore } from "@/store/draft";

export default class OneClickService {
  static prefillOneClick(data) {
    if (
      window.globalConfig.authorized1click &&
      !data.doNotSend1CLICKOffers &&
      (data.oneClickCC || data.oneClickSEPA)
    ) {
      useDraftStore().selectPaymentMethod(
        data.oneClickSEPA ? "sepa" : "bank_card",
      );
      useDraftStore().eligibleToOneClick(true);
      useDraftStore().updateActionLegacy("createOneClick");
      useDraftStore().updateFullAddress(
        `${data.shippingStreet3}, ${data.shippingZipCode}, ${data.shippingCity}`,
      );
      useDraftStore().formValid(true);
      useDraftStore().approveCgs(true);
      useDraftStore().approveCgv(true);
      return {
        acceptCgs: true,
        acceptCgv: true,
      };
    }
    return {};
  }

  static isEligibleToOneClick() {
    return (
      useDraftStore().isEligibleToOneClick &&
      window.globalConfig.authorized1click
    );
  }
}
