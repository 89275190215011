export default class Navigation {
  // Warning : you probably want to use the tracking store getter instead of this method
  static getQueryParams() {
    const url = new URL(window.location);
    return url.searchParams;
  }

  static redirectUrl(url, keepQueryString = true) {
    const fromURL = new URL(window.location);
    const toUrl = new URL(window.location.origin + url);

    if (keepQueryString) {
      const paramsObj = fromURL.searchParams;
      for (const [key, value] of paramsObj) {
        if (!toUrl.searchParams.has(key)) {
          toUrl.searchParams.set(key, value);
        }
      }
    }

    window.location.href = toUrl.href;
  }
}
