<template>
  {{ renderHTML(label) }}
</template>

<script>
export default {
  name: "ChoiceFreeLabel",
  props: {
    choice: {
      type: Object,
    },
  },
  computed: {
    label() {
      // This is computed because choice may change after init
      return this.choice.freeLabel;
    },
  },
};
</script>
