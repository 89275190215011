<template>
  <!-- TODO : Repair it. It doesn't work-->
  <div :id="'carousel' + categoryid" class="carousel slide" v-if="products">
    <div class="carousel-inner">
      <div class="row">
        <div
          :class="
            'carousel-item product-item ' +
            (index === 0 ? 'active' : '') +
            ' col-sm-3'
          "
          v-for="(product, index) in products"
          v-bind:data-product-id="product.id"
        >
          <div class="product-item-info">
            {{ product }}
            <ProductListImage
              :product="product"
              :isProductListFromParent="isProductListFromParent"
            />
            <div class="product-item-details">
              <h3 class="product-item-name">
                <a class="product-item-link" :href="product.url">
                  {{ product.name }}
                </a>
              </h3>
              <Ratings
                v-if="showratingstar && !product.hideCustomerReviews"
                mode="skeepers_product__stars"
                :productCodeFromParent="product.code"
              ></Ratings>
              <template v-if="getDefaultChoice(product)">
                <Price
                  :product="getDefaultChoice(product)"
                  class="price-container"
                />
              </template>
              <p class="listing-short-description">
                {{ product.longName }}
              </p>
              <template v-if="showaddtocart && getDefaultChoice(product)">
                <AddToCart
                  :item="fillProductWithChoice(product)"
                  :btnlabel="addToCartLabel"
                />
              </template>
              <a v-else :href="product.url">
                <span class="goto-product">{{ $t("labels.more") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="'#carousel' + categoryid"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="'#carousel' + categoryid"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import AddToCart from "@/components/AddToCart.vue";
import Utils from "@/services/utils";
import Price from "@/components/productlist/subcomponents/Price.vue";
import Ratings from "@/components/product/Ratings.vue";
import ProductListImage from "@/components/productlist/subcomponents/Image.vue";

export default {
  name: "ProductListSlider",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryid: {
      type: String,
      default: "",
    },
    showaddtocart: {
      type: Boolean,
      default: false,
    },
    addToCartLabel: {
      type: String,
    },
    showprocessorder: {
      type: Boolean,
      default: false,
    },
    processordertext: {
      type: String,
    },
    showratingstar: {
      type: Boolean,
      default: false,
    },
    getDefaultChoice: {
      type: Function,
      required: true,
    },
    fillProductWithChoice: {
      type: Function,
      required: true,
    },
    isProductListFromParent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductListImage,
    Price,
    Ratings,
    AddToCart,
  },
  methods: {
    displayDuration(duration, startingPrice = false) {
      return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
    },
  },
};
</script>
