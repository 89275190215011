<template>
  <div class="page-loader" v-if="isLoading"></div>
</template>

<script>
import { useCartStore } from "@/store/cart";

export default {
  name: "Loader",
  computed: {
    isLoading() {
      return useCartStore().isCartLoading;
    },
  },
};
</script>
