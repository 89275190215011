<template>
  <span class="subtitle" v-if="longName" v-html="longName"></span>
</template>

<script>
export default {
  name: "ProductLongName",
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      longName: "",
    };
  },
  mounted() {
    this.longName = this.product?.longName || window.productDetails.longName;
  },
};
</script>
