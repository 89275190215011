<template>
  <div
    v-if="argument"
    v-html="renderHTML(argument)"
    :data-product-component-type="`productargument${argumentkey}`"
    :class="`productargument${argumentkey}`"
  ></div>
</template>

<script>
export default {
  name: "ProductArgument",
  props: {
    argumentkey: {
      type: String,
      required: true,
      default: "1",
    },
    product: {
      type: Object,
    },
  },
  data() {
    return {
      argument: null,
    };
  },
  mounted() {
    if (this.product) {
      this.argument = this.product[`argument${this.argumentkey}`];
    } else if (
      window.productDetails &&
      window.productDetails[`argument${this.argumentkey}`]
    ) {
      this.argument = window.productDetails[`argument${this.argumentkey}`];
    }
  },
};
</script>
