<template>
  <span class="vanguard-label" v-if="label" v-html="renderHTML(label)"></span>
</template>

<script>
export default {
  name: "ChoiceVanguardLabel",
  props: {
    choice: {
      type: Object,
    },
  },
  computed: {
    label() {
      // This is computed because choice may change after init
      return this?.choice?.vanguardLabel;
    },
  },
};
</script>
