<template>
  <div v-for="(upsellChoice, index) in upsellChoices" class="upsell-box">
    <div class="radio-btn-text">
      <div class="radio-upsell">
        <input
          type="checkbox"
          role="switch"
          :id="'upsell' + upsellChoice.id"
          v-model="upsellChoice.upsellState"
          @click="(event) => toggleAction(event, upsellChoice)"
        />
      </div>
      <template v-if="upsellChoice.upsellState">
        <!-- upselled : show cart item choice details -->
        <div class="text-upsell">
          <span>
            <span class="highlighted-text"
              ><UpsellDiff
                :cartItemChoice="upsellChoice"
                :upsellChoice="cartItem.choice"
            /></span>
            <ChoiceUpsellDescription :choice="cartItem.choice" />
          </span>
        </div>
        <div class="price-upsell">
          <ChoicePrice :choice="cartItem.choice" />
        </div>
      </template>
      <template v-else>
        <!-- upsell suggestion : show upsell suggested choice details -->
        <div class="text-upsell">
          <span>
            <span class="highlighted-text"
              ><UpsellDiff
                :cartItemChoice="cartItem.choice"
                :upsellChoice="upsellChoice"
            /></span>
            <ChoiceUpsellDescription :choice="upsellChoice" />
          </span>
        </div>
        <div class="price-upsell">
          <ChoicePrice :choice="upsellChoice" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ChoiceUpsellDescription from "@/components/choice/UpsellDescription.vue";
import UpsellDiff from "@/components/cart/table/UpsellDiff.vue";
import ChoicePrice from "@/components/choice/Price.vue";
import AbTests from "@/services/abTests";
import CartService from "@/services/cart";
import { useCartStore } from "@/store/cart";
import { useUpselledStore } from "@/store/upselled";

export default {
  name: "CartTableUpsellList",
  props: {
    cartItem: {
      type: Object,
    },
  },
  components: {
    ChoiceUpsellDescription,
    UpsellDiff,
    ChoicePrice,
  },
  data() {
    return {
      cart: null,
      upsellChoices: [],
    };
  },
  computed: {
    lastCartUpdate() {
      return useCartStore().lastUpdate;
    },
  },
  mounted() {
    this.cart = this.getCart();
    this.upsellChoices = this.findUpsellChoices();
  },
  watch: {
    lastCartUpdate: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.cart = this.getCart();
      },
    },
    cart: {
      handler(newValue, oldValue) {
        if (!window?.globalConfig?.domainId) {
          return;
        }
        this.upsellChoices = this.findUpsellChoices();
      },
      deep: true,
    },
  },
  methods: {
    getCart() {
      return useCartStore().cart;
    },
    findUpsellChoices() {
      if (!window?.productDetails?.upsells) {
        return [];
      }
      let finalUpsellChoices = [];
      const upsells = window.productDetails.upsells;

      const upsell = upsells.find(
        (upsell) => upsell.parentId === this.cartItem.choice.id,
      );

      const upsellStateByChoiceId = {};
      if (upsell) {
        const abGroups = upsell.children;
        for (const abGroup of abGroups) {
          const electedAbChoice = AbTests.electItemOfGroup(abGroup);
          upsellStateByChoiceId[electedAbChoice.id] = false;
        }
      } else {
        // check if choice is an upselled choice
        const downgradeChoice = useUpselledStore().getDowngradeChoice(
          this.cartItem.choice,
        );
        if (downgradeChoice) {
          upsellStateByChoiceId[downgradeChoice.id] = true;
        }
      }

      if (upsellStateByChoiceId) {
        // get full object choice
        const choiceIds = Object.keys(upsellStateByChoiceId);
        const choices = window.productDetails.choices.filter((choice) =>
          choiceIds.includes(choice.id),
        );

        for (const choice of choices) {
          choice.upsellState = upsellStateByChoiceId[choice.id];
          finalUpsellChoices.push(choice);
        }

        console.log("upsellChoices", finalUpsellChoices);
        return finalUpsellChoices;
      }
    },
    toggleAction(event, choice) {
      console.log("toggleAction", choice);

      const downgradeChoice = Object.assign({}, this.cartItem.choice);

      this.cartItem.qtyIncr = 1;
      this.cartItem.qty = 0;
      this.cartItem.choice = choice;

      CartService.addToCart(this.cartItem);

      if (choice.upsellState) {
        useUpselledStore().downgradeChoice(choice);
      } else {
        useUpselledStore().upgradeChoice(downgradeChoice, choice);
      }
    },
  },
};
</script>
