import { defineStore } from "pinia";
import { constants } from "@/constants";
import sha1 from "js-sha1";
import MultiLocalStorage from "@/services/multiLocalStorage";

export const useCartStore = defineStore("cart", {
  state: () => ({
    _cart: null,
    _loading: false,
    _lastUpdate: null,
  }),
  getters: {
    cart: (state) => {
      if (!state._cart) {
        let cart = MultiLocalStorage.get(
          constants.ECOM_CART,
          window?.globalConfig?.domainId,
        );
        state._cart = cart ? cart : { items: [] };
      }

      if (!state._cart.items) {
        state._cart.items = [];
      }

      return state._cart;
    },
    isCartLoading: (state) => {
      return state._loading;
    },
    lastUpdate: (state) => state._lastUpdate,
  },
  actions: {
    setLastUpdate() {
      this._lastUpdate = new Date();
    },
    updateCartLoading(loading) {
      this._loading = loading; // global loader
    },
    updateCart(cart) {
      this._cart = cart;
      this.saveCart();
    },
    saveCart() {
      MultiLocalStorage.set(
        constants.ECOM_CART,
        window?.globalConfig?.domainId,
        this._cart,
      );
      this._lastUpdate = new Date();
    },
    calcSignature() {
      const cart = this.cart;
      if (!cart) {
        return null;
      }
      let signature = "";
      for (const item of cart.items) {
        if (signature) {
          signature += "+";
        }
        signature += item.choice.id + "x" + item.qty;
      }
      return sha1(signature); // choice1xqty1+choice2xqty2+...+choiceNxqtyN
    },
  },
});
