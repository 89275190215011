<template>
  <img v-if="logoSrc" :src="logoSrc" :alt="alt" :class="cssClass" />
</template>

<script>
export default {
  name: "Logo",
  props: {
    alt: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logoSrc: null,
    };
  },
  mounted() {
    this.logoSrc = window.globalConfig.logo;
  },
};
</script>
