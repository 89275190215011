<template>
  <div class="cookie-declaration"></div>
</template>

<script>
export default {
  name: "CookieDeclaration",
  props: ["uuid"],
  mounted() {
    const cookieDeclarationElements =
      document.getElementsByClassName("CookieDeclaration");

    if (cookieDeclarationElements.length === 0) {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        `https://consent.cookiebot.com/${this.uuid}/cd.js`,
      );
      script.setAttribute("type", "text/javascript");
      document
        .getElementsByClassName("cookie-declaration")[0]
        .appendChild(script);
    }
  },
};
</script>
