<template>
  <template v-if="!isSellEnded">
    <div class="add-to-cart">
      <input
        v-if="showqty && shopAllowQtyModification"
        type="number"
        v-model="qtyIncr"
        min="1"
        step="1"
      />
      <button
        type="button"
        class="add-to-cart-btn"
        @click.prevent="(event) => addToCartAction(event)"
      >
        {{ btnlabel ? btnlabel : $t("labels.addToCart") }}
      </button>
    </div>
  </template>
  <template v-else>
    <div class="add-to-cart-ended">
      {{ $t("labels.sellEnded") }}
    </div>
  </template>
</template>

<script>
import CartService from "@/services/cart";
import { useDraftStore } from "@/store/draft";
import ProductService from "@/services/product";

export default {
  name: "AddToCart",
  props: {
    item: Object,
    showqty: Boolean,
    changeChoice: Object,
    btnlabel: String,
  },
  data() {
    return {
      productDetails: {},
      isSellEnded: false,
      intervalSellEnded: null,
      shopAllowQtyModification: false,
      qtyIncr: 1,
    };
  },
  mounted() {
    this.productDetails = window.productDetails;
    if (this.productDetails?.endDate) {
      this.isSellEnded = this.productDetails.endDate <= Date.now();
      this.intervalSellEnded = setInterval(() => {
        this.isSellEnded = this.productDetails.endDate <= Date.now();
      }, 1000);
    }
    this.shopAllowQtyModification =
      !!window.globalConfig.shopAllowQtyModification;
  },
  beforeDestroy() {
    if (this.intervalSellEnded) {
      clearInterval(this.intervalSellEnded);
    }
  },
  methods: {
    addToCartAction(event) {
      if (this.item) {
        // product list
        this.item.qtyIncr = this.qtyIncr;
        if (this.changeChoice) {
          this.item.qty = 0; // change of choice, reset qty
          this.item.choice = this.changeChoice;
        } else {
          this.item.choice = ProductService.getDefaultChoice(this.item);
        }
        CartService.addToCart(this.item);
      } else if (this.productDetails) {
        // product view
        const productToAdd = Object.assign({}, this.productDetails);
        productToAdd.qtyIncr = this.qtyIncr;
        const selectedChoiceId = useDraftStore().selectedChoice;

        if (selectedChoiceId) {
          productToAdd.choice = this.productDetails.choices.find(
            (choice) => choice.id === selectedChoiceId,
          );

          CartService.addToCart(productToAdd);
        } else {
          console.error("You need to choose a choice");
        }
      }
    },
  },
};
</script>
