<template>
  <div v-if="shortDescription" v-html="renderHTML(shortDescription)"></div>
</template>

<script>
export default {
  name: "CategoryShortDescription",
  props: [],
  data() {
    return {
      shortDescription: "",
    };
  },
  mounted() {
    this.shortDescription = window.category.shortDescription;
  },
};
</script>
