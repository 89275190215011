<template>
  <span
    class="featured-product-name"
    v-if="featuredProductName"
    v-html="renderHTML(featuredProductName)"
  ></span>
  <meta
    itemprop="headline"
    v-if="featuredProductName"
    :content="renderHTML(featuredProductName)"
  />
</template>

<script>
export default {
  name: "FeaturedProductName",
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      featuredProductName: "",
    };
  },
  mounted() {
    this.featuredProductName =
      this.product?.featuredProductName ||
      window.productDetails.featuredProductName;
  },
};
</script>
