<template>
  <div>
    <button
      v-if="enabled && !messages.length"
      type="button cancel-oneclick"
      @click.prevent="clickAction"
    >
      {{ $t("labels.cancelOneClick") }}
    </button>
  </div>

  <div
    v-for="message in messages"
    :class="`alert alert-${message.type}`"
    role="alert"
  >
    {{ message.message }}
  </div>
</template>

<script>
import Orion from "@/services/orion";
import { useOrderHistoryStore } from "@/store/orderHistory";

export default {
  name: "CheckoutButtonCancelOneClick",
  data() {
    return {
      messages: [],
      messageTimeout: null,
    };
  },
  watch: {
    messages: {
      handler(newValue, oldValue) {
        if (this.messages.length === 0) {
          return;
        }
        if (this.messageTimeout) {
          window.clearTimeout(this.messageTimeout);
        }
        this.messageTimeout = window.setTimeout(() => {
          this.messages = [];
        }, 5000);
      },
      deep: true,
    },
  },
  methods: {
    enabled() {
      return this.hasValidOrder();
    },
    hasValidOrder() {
      return (
        useOrderHistoryStore().orderHistory.length > 0 &&
        useOrderHistoryStore().orderHistory.slice(-1)[0]?.order?.externalOrderId
      );
    },
    clickAction() {
      Orion.updateContactOnOrder({
        externalOrderId:
          useOrderHistoryStore().orderHistory.slice(-1)[0].order
            .externalOrderId,
        has1ClickOptOut: true,
      })
        .then((response) => {
          this.messages.push({
            type: "success",
            message: this.$t("labels.ok"),
          });
        })
        .catch((error) => {
          this.messages.push({ type: "danger", message: error.message });
        });
    },
  },
};
</script>
