<template>
  <div v-if="argument2" v-html="renderHTML(argument2)"></div>
</template>

<script>
export default {
  name: "ProductArgument2",
  data() {
    return {
      argument2: null,
    };
  },
  mounted() {
    this.argument2 = window.productDetails.argument2;
  },
};
</script>
